/** Parsley form errors */
form[data-parsley-validate] input.parsley-error,
form[data-parsley-validate] input.parsley-error:focus {
  border-color: #e25c59;
  -webkit-box-shadow: 0 0 0 2px rgba(226, 92, 89, 0.25);
  box-shadow: 0 0 0 2px rgba(226, 92, 89, 0.25);
}
form[data-parsley-validate] .parsley-errors-list {
  margin: -12px 0 12px 0;
  padding: 0;
  list-style-type: none;
}
form[data-parsley-validate] .parsley-errors-list li {
  color: #e25c59;
  font-size: 12px;
  font-weight: 600;
}
form[data-parsley-validate] input.parsley-success + .parsley-errors-list {
  margin: 0;
}
